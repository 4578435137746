/* eslint-disable  */
import { useCallback } from 'react';
import { useParams, useNavigate, useLocation, Router, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import ajax from '../utils/ajaxHelper';
import { getHostType, isEmpty, getRootSubdomain } from '../utils';
import { encryptData } from '../utils/crypt';
import CryptoJS from 'crypto-js';
import { AUTH_PAYLOAD, PRICING_STATE } from '../constants/globalConstants';

const rootSubdomain = getRootSubdomain(window.location.hostname);

const checkIfLoggedIn = () => {
  let isLoggedIn = false;
  const userCookie = Cookies.get('user', { domain: rootSubdomain });
  if (userCookie) {
    isLoggedIn = true;
  }
  return isLoggedIn;
};
const usePracticeAccountRegister = () => {
  const { registerToken } = useParams();
  const navigate = useNavigate();
  const state = useLocation();
  const callAPi = useCallback(
    async (url, values, { resetForm, setErrors, setStatus, setSubmitting }) => {
      setSubmitting(true);
      let data = values;
      try {
        await ajax.post(
          url,
          {
            ...values,
            token: registerToken,
          },
          {
            networkParams: { showLoader: false },
          },
        );

        resetForm();
        setStatus({ success: true });
      } catch (error) {
        setStatus({ success: false });
        setErrors({ submit: error.message });
        throw error;
      } finally {
        setSubmitting(false);
      }
    },
    [registerToken],
  );

  const validateToken = useCallback(async () => {
    try {
      if (registerToken) {
        let result = await ajax.post(
          'register-token-validate-for-practice-create',
          {
            token: registerToken,
          },
          {
            networkParams: { showLoader: false },
          },
        );

        return result;
      } else {
        if (checkIfLoggedIn()) {
          navigate('/not-found');
          return;
        }

        navigate('/login');
      }
    } catch (error) {
      if (checkIfLoggedIn()) {
        navigate('/not-found');
        return;
      }

      navigate('/login');
    } finally {
      sessionStorage.clear();
    }
  }, [registerToken]);

  const validateTokenFromSession = useCallback(async () => {
    try {
      let storeVal = sessionStorage.getItem('store');

      if (storeVal) {
        let parsedJson = JSON.parse(storeVal);

        let result = await ajax.post(
          'register-token-validate-for-practice-create',
          {
            token: parsedJson.token,
          },
          {
            networkParams: { showLoader: false },
          },
        );

        return result;
      } else {
        if (checkIfLoggedIn()) {
          navigate('/not-found');
          return;
        }

        navigate('/login');
      }
    } catch (error) {
      if (checkIfLoggedIn()) {
        navigate('/not-found');
        return;
      }

      navigate('/login');
    }
  }, []);

  const populateData = useCallback(
    async (callback) => {
      try {
        let tokenResponse = await validateToken();

        if (tokenResponse && tokenResponse.status) {
          let result = await ajax.post(
            'get-accountData-and-states',
            {
              token: registerToken,
              countryName: 'United States',
            },
            {
              networkParams: { showLoader: false },
            },
          );
          callback(null, result);
        }
      } catch (error) {
        console.log(error);

        if (checkIfLoggedIn()) {
          navigate('/not-found');
          return;
        }

        navigate('/login');
      }
    },
    [validateToken],
  );

  const navigateToAddUsers = useCallback(
    async (values, actions) => {
      try {
        //    await callAPi('create-practice-account', values, actions);
        ///   navigate('../../addusers');

        if (registerToken) {
          values.token = registerToken;
          sessionStorage.setItem('store', JSON.stringify(values));

          navigate('../../addusers', { state: values });
        }
      } catch (e) {
        console.log(e);
      }
    },
    [registerToken],
  );

  // const callAPi = useCallback(
  //   async (url, values, { resetForm, setErrors, setStatus, setSubmitting }) => {
  //     setSubmitting(true);
  //     let data = values;
  //     try {
  //       await ajax.post(
  //         url,
  //         {
  //           ...values,
  //           token: registerToken,
  //         },
  //         {
  //           networkParams: { showLoader: false },
  //         },
  //       );

  //       resetForm();
  //       setStatus({ success: true });
  //     } catch (error) {
  //       setStatus({ success: false });
  //       setErrors({ submit: error.message });
  //       throw error;
  //     } finally {
  //       setSubmitting(false);
  //     }
  //   },
  //   [registerToken],
  // );

  const bulkUploadPracticePatient = useCallback(
    async (values, callback) => {
      const formData = new FormData();
      // eslint-disable-next-line no-debugger
      formData.append('drop', values.drop);
      const userCookie = Cookies.get('user', { domain: rootSubdomain });
      //const userData = JSON.parse(atob(userCookie));
      const key = process.env.REACT_APP_ENCRYPTION_KEY;
      let bytes = CryptoJS.AES.decrypt(userCookie, key);
      const userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      const id = userData._id;
      formData.append('id', id);

      try {
        console.log('formData', formData);
        const res = await ajax
          .post('patient-bulk-upload', formData, {
            networkParams: { showLoader: false },
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            callback(res);
          })
          .catch((error) => {
            callback(error);
          });
      } catch (e) {
        console.log(e);
      }
    },
    [callAPi],
  );
  const addUsersIntegration = useCallback(async (values, actions) => {
    try {
      let result = await ajax.post(
        'register-token-validate-for-practice-create', //'register-token-validate'
        {
          token: registerToken,
        },
        {
          networkParams: { showLoader: false },
        },
      );

      return result;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const checkForUniqueEmailId = useCallback(async (values, count, actions) => {
    actions.setSubmitting(true);

    try {
      let result = await ajax.post(
        'check-for-unique-emailId',
        {
          emailId: values,
        },
        {
          networkParams: { showLoader: false },
        },
      );
      actions.setStatus({ success: true });

      if (result.isExisted) {
        actions.setFieldError('emailId' + count, result.fieldMessage);
        actions.setEmailObj((emailObj) => {
          localStorage.setItem(
            'emailObj',
            JSON.stringify({
              ...emailObj,
              validEmail: { ...emailObj.validEmail, ['emailId' + count]: result.fieldMessage },
            }),
          );

          return {
            ...emailObj,
            validEmail: { ...emailObj.validEmail, ['emailId' + count]: result.fieldMessage },
          };
        });
      }

      return result;
    } catch (error) {
      actions.setStatus({ success: false });
      actions.setFieldError('emailId' + count, 'Error validating email');
      actions.setEmailObj((emailObj) => {
        localStorage.setItem(
          'emailObj',
          JSON.stringify({
            ...emailObj,
            validEmail: { ...emailObj.validEmail, ['emailId' + count]: 'Error validating email' },
          }),
        );

        return {
          ...emailObj,
          validEmail: { ...emailObj.validEmail, ['emailId' + count]: 'Error validating email' },
        };
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, []);

  const processResult = (sampleObj) => {
    let data = [];

    let allKeys = Object.keys(sampleObj); //fetched the key at second index
    let objLength = allKeys.length;
    for (let j = 0; j < objLength / 4; j++) {
      let eachObj = {};

      for (let i = 0; i < 4; i++) {
        let keyForindex = allKeys[(i * objLength) / 4 + j];

        if (i == 0) {
          eachObj.emailId = sampleObj[keyForindex];
        }

        if (i == 1) {
          eachObj.firstName = sampleObj[keyForindex];
        }
        if (i == 2) {
          eachObj.lastName = sampleObj[keyForindex];
        }
        if (i == 3) {
          eachObj.userType = sampleObj[keyForindex];
        }
      }

      data.push(eachObj);
    }
    return data;
  };

  const submitHandler = useCallback(async (values, callback) => {
    // setSubmitting(true);
    try {
      //Removes login info if any
      // Cookies.remove('token');
      // Cookies.remove('hosttype');
      // Cookies.remove('user');

      //
      const password = state.state.password;
      state.state.password = encryptData(state.state.password);
      state.state.confirmPassword = state.state.password;
      let tokenResponse = await validateTokenFromSession(); //Additional token check fo Token
      if (tokenResponse && tokenResponse.status) {
        let createAccResult = await ajax.post(
          'create-practice-account',
          {
            ...state.state, // from state
          },
          {
            networkParams: { showLoader: false },
          },
        );

        var ordered = {};
        Object.keys(values)
          .sort()
          .forEach(function (key) {
            if (values[key] && values[key].length > 0) {
              ordered[key] = values[key];
            }
          });
        if (!isEmpty(ordered)) {
          let processedResult = processResult(ordered);

          let result = await ajax.post(
            'add-users-to-practice',
            {
              userDetails: processedResult,
              practRef: createAccResult.practRef,
            },
            {
              networkParams: { showLoader: false },
            },
          );
        }

        // setStatus({ success: true });

        if (checkIfLoggedIn()) {
          navigate('/not-found');
          return;
        }

        sessionStorage.setItem(
          AUTH_PAYLOAD,
          JSON.stringify({
            emailId: state.state.contactEmailId,
            password: password,
          }),
        );

        navigate('../pricing', {
          state: {
            ...state.state,
            practiceId: createAccResult.practRef,
            practiceName: createAccResult.practiceDetails
              ? createAccResult.practiceDetails.name
              : null,
          },
        });
      } else {
        if (checkIfLoggedIn()) {
          navigate('/not-found');
        } else {
          return callback(result, null);
        }
      }
    } catch (error) {
      // setSubmit(false);
      // setStatus({ success: false });
      if (checkIfLoggedIn()) {
        navigate('/not-found');
      } else {
        return callback(null, error);
        navigate('/login');
      }
    } finally {
      // setSubmitting(false);
      // sessionStorage.clear();
      // localStorage.clear();
    }
  }, []);

  const submitHandlerDepricated = useCallback(async (values, actions) => {
    actions.setSubmitting(true);

    try {
      var ordered = {};
      Object.keys(values)
        .sort()
        .forEach(function (key) {
          if (values[key] && values[key].length > 0) {
            ordered[key] = values[key];
          }
        });

      let processedResult = processResult(ordered);

      let result = await ajax.post(
        'add-users-to-practice',
        {
          userDetails: processedResult,
        },
        {
          networkParams: { showLoader: false },
        },
      );
      actions.setStatus({ success: true });

      // return result;
    } catch (error) {
      actions.setStatus({ success: false });
    } finally {
      actions.setSubmitting(false);
    }
  }, []);

  const checkForUniqueMobileno = useCallback(async (values, actions) => {
    actions.setSubmitting(true);

    try {
      let result = await ajax.post(
        'check-for-mobileno',
        {
          userMobileNo: values,
        },
        {
          networkParams: { showLoader: false },
        },
      );

      if (result.isExisted) {
        actions.setFieldError('userMobileNo', result.fieldMessage);
        actions.setInvalidNumber({ num: values, errMsg: result.fieldMessage });
      }

      return result;
    } catch (error) {
      actions.setStatus({ success: false });
      actions.setFieldError('userMobileNo', 'Error validating mobile number');
      actions.setInvalidNumber({ num: values, errMsg: 'Error validating mobile number' });
    } finally {
      actions.setSubmitting(false);
    }
  }, []);

  const populateContries = useCallback(async (values, callback) => {
    try {
      let result = await ajax.post(
        'get-countries',
        {},
        {
          networkParams: { showLoader: false },
        },
      );

      if (result.status && result.data) {
        return callback(null, result.data);
      }

      // return  callback(result,null)
    } catch (error) {
      return callback(error, null);
    }
  }, []);

  return {
    validateToken,
    populateData,
    bulkUploadPracticePatient,
    checkForUniqueEmailId,
    submitHandler,
    navigateToAddUsers,
    checkForUniqueMobileno,
    validateTokenFromSession,
    populateContries,
  };
};

export default usePracticeAccountRegister;
