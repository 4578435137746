/* eslint-disable */
import * as firebase from 'firebase/app';
import 'firebase/messaging';
const initializedFirebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyB5N9rpx0QltFLz9UHBOIIKVNJu__PtroQ',
  authDomain: 'mliq-286710.firebaseapp.com',
  databaseURL: 'https://mliq-286710.firebaseio.com',
  projectId: 'mliq-286710',
  storageBucket: 'mliq-286710.appspot.com',
  messagingSenderId: '221935427336',
  appId: '1:221935427336:web:6fb839f29c4e4b605416bd',
});
let messaging = {};
if (firebase.messaging.isSupported()) {
  console.log(firebase.messaging.isSupported());
  messaging = initializedFirebaseApp.messaging();
}
export { messaging };
