import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    logobox: {
      // display: 'flex',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0.7),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      textAlign: 'center',
      display: 'block',
      // position: 'fixed',
      // top: 0,
      // left: 0,
      zIndex: 9,
      backgroundColor: '#f3efea',
      height: 80,
    },
    logo: {
      width: 126,
      height: 60,
    },
  };
});

export default useStyles;
