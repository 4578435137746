/* eslint-disable  */
import { useCallback } from 'react';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import ajax from '../utils/ajaxHelper';
import { useParams, useNavigate, useLocation, Router, Route } from 'react-router-dom';
import usePracticeAccountRegister from './usePracticeAccountRegister';

import { getRootSubdomain } from '../utils';
import {
  CREATE_SUBSCRIPTION_PAYLOAD,
  IS_FREE_TRIAL_IMPLEMENTED,
  LIFETIME_COUPON,
  PAYMENT_INTENT_CLIENT_SECRET,
  SELECTED_PRODUCT,
  SETUP_INTENT_CLIENT_SECRET,
  STRIPE_CLIENT_SECRET,
  STRIPE_SUBSCRIPTION_ID,
} from '../constants/globalConstants';
const rootSubdomain = getRootSubdomain(window.location.hostname);

export const setPackageInfo = () => {
  const setPackage = useCallback(async (data, callback) => {
    const userCookie = Cookies.get('user', { domain: rootSubdomain });
    const key = process.env.REACT_APP_ENCRYPTION_KEY;
    let bytes = CryptoJS.AES.decrypt(userCookie, key);
    const userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    const id = userData._id;
    const practiceId = userData.practiceId;
    try {
      let result = await ajax.post(
        'set-package-info',
        {
          userId: id,
          packages: data.packages,
          promocode: data.promocode,
          practiceId: practiceId,
        },
        {
          networkParams: { showLoader: false },
        },
      );
      return callback(null, result);
    } catch (error) {
      return callback(null, null);
    }
  });
  return {
    setPackage,
  };
};
/*
  Created By: Saurabh Pandey
  Date:2020-07-15
  Description: My Patient page 
*/

export const fetchInvoiceDetails = () => {
  const populateInvoiceData = useCallback(async (id, callback) => {
    try {
      let result;

      result = await ajax.post(
        'get-invoice-list',
        {
          practiceId: id,
        },
        {
          networkParams: { showLoader: false },
        },
      );

      return callback(null, result);
    } catch (error) {
      console.log(error);
    }
  });
  return {
    populateInvoiceData,
  };
};

export const getSavedData = () => {
  const populateSavedData = useCallback(async (callback) => {
    const userCookie = Cookies.get('user', { domain: rootSubdomain });
    const key = process.env.REACT_APP_ENCRYPTION_KEY;
    let bytes = CryptoJS.AES.decrypt(userCookie, key);
    const userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    const practiceId = userData.practiceId;
    try {
      let result;
      result = await ajax.post(
        'get-package-info',
        {
          practiceId: practiceId,
        },
        {
          networkParams: { showLoader: false },
        },
      );

      return callback(null, result);
    } catch (error) {
      console.log(error);
    }
  });
  return {
    populateSavedData,
  };
};

export const setPaymentInfo = () => {
  const setPayment = useCallback(async (data, callback) => {
    const userCookie = Cookies.get('user', { domain: rootSubdomain });
    const key = process.env.REACT_APP_ENCRYPTION_KEY;
    let bytes = CryptoJS.AES.decrypt(userCookie, key);
    const userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    const id = userData._id;
    const practiceId = userData.practiceId;
    const emailId = userData.emailId;
    const latestPaymentFailed = userData.latestPaymentFailed;
    try {
      let date = data.expirationDate.split('/');
      let expDate = date[0] + '20' + date[1];
      let result = await ajax.post(
        'final-payment',
        {
          emailId: emailId,
          cvv: data.cvv,
          cardNumber: data.cardnumber,
          cardType: data.cardType,
          expDate: expDate,
          practiceId: practiceId,
          paymentType: 'cc',
          latestPaymentFailed: latestPaymentFailed,
        },
        {
          networkParams: { showLoader: false },
        },
      );
      const key = process.env.REACT_APP_ENCRYPTION_KEY;
      const user = Cookies.get('user', { domain: 'rootSubdomain' });
      let bytes = CryptoJS.AES.decrypt(user, key);
      const parsedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      parsedUser.firstPaymentMade = true;
      parsedUser.latestPaymentFailed = false;
      let userData = CryptoJS.AES.encrypt(JSON.stringify(parsedUser), key).toString();
      Cookies.set('user', userData);

      setTimeout(function () {
        window.location.reload();
      }, 1500);

      return callback(null, result);
    } catch (error) {
      return callback(null, null);
    }
  });
  return {
    setPayment,
  };
};

/*
  Created By: Vanita Khamkar
  Date:2021-05-03
  Description: Generate Token for payment 
*/
export const usePayment = () => {
  const paymentToken = Cookies.get('paymentToken', { domain: rootSubdomain });
  const { registerToken } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { validateTokenFromSession } = usePracticeAccountRegister();

  const navigateToPricing = useCallback(
    async (values, actions) => {
      try {
        if (registerToken) {
          values.token = registerToken;
          sessionStorage.setItem('store', JSON.stringify(values));

          navigate('../../pricing', { state: values });
        }
      } catch (e) {
        console.log(e);
      }
    },
    [registerToken],
  );

  const navigateToAddPayment = useCallback(async (values, callback) => {
    try {
      if (values) {
        const code = values.promocode;

        if (code) {
          const promoCode = await validatePromo(code);
          if (promoCode.status) {
            values.coupon = promoCode.data.coupon;
          }
        }
        sessionStorage.setItem('pricing', JSON.stringify(values));
        savePricingDetails(values, (err, data) => {
          if (err) {
            return callback(err, null);
          }
        });
      }
    } catch (e) {
      console.log(e);
      return callback(e, null);
    }
  }, []);

  const validatePromo = async (code) => {
    try {
      const result = await ajax.post(
        'stripe/coupon/validate',
        { code: code },
        {
          networkParams: { showLoader: false },
        },
      );

      return result;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const verifyPaymentAlreadyDone = useCallback(async (data, callback) => {
    try {
      let storeVal = sessionStorage.getItem('store');
      if (storeVal) {
        let parsedJson = JSON.parse(storeVal);

        let tokenResponse = await validateTokenFromSession();

        if (tokenResponse && tokenResponse.status) {
          let result = await ajax.post(
            'verify-payment-already-made',
            {
              token: parsedJson.token,
              practRef: state.practiceId,
            },
            {
              networkParams: { showLoader: false },
            },
          );
          if (result.status && result.message == 'First Payment Already Done.') {
            navigate('../../login');
          }
          return callback(null, result);
        } else {
          navigate('../../login');
        }
      } else {
        navigate('../../login');
      }
    } catch (e) {
      console.log(e);
      navigate('../../login');
    }
  });

  const generateTokenForPracticePayment = useCallback(async (data, callback) => {
    try {
      let storeData = sessionStorage.getItem('store')
        ? JSON.parse(sessionStorage.getItem('store'))
        : {};

      let result = {};
      if (data) {
        let date = data.expDate.split('/');
        let expDate = date[0] + '20' + date[1];
        result = await ajax.post(
          'genarte-token-for-payment',
          {
            cardNumber: data.cardNumber,
            cardType: data.cardType,
            expDate: expDate,
            cvv: data.cvv,
            emailId: storeData.contactEmailId,
          },
          {
            networkParams: { showLoader: false },
          },
        );
      }
      if (!result.status) {
        return callback(result, null);
      }
      return callback(null, result);
    } catch (e) {
      console.log(e);
      return callback(e, null);
    }
  }, []);

  const makePaymentForPractice = useCallback(
    async (data, callback) => {
      try {
        let storeData = sessionStorage.getItem('store')
          ? JSON.parse(sessionStorage.getItem('store'))
          : {};
        // state = state.state;
        let date = data.expDate.split('/');
        let expDate = date[0] + '20' + date[1];

        // const paymentToken = Cookies.get('paymentToken');
        if (data) {
          let date = data.expDate.split('/');
          let expDate = date[0] + '20' + date[1];
          let response = await ajax.post(
            'make-payment-for-practice',
            {
              cardNumber: data.cardNumber,
              cardType: data.cardType,
              expDate: expDate,
              cvv: data.cvv,
              emailId: storeData.contactEmailId,
              firstName: storeData.primaryFirstName,
              lastName: storeData.primaryLastName,
              emailId: storeData.contactEmailId,
              address1: storeData.address1,
              address2: storeData.address2,
              city: storeData.city ? storeData.city : '',
              state: storeData.state ? storeData.state : '',
              country: storeData.country ? storeData.country : 'US',
              suburb: storeData.suburb ? storeData.suburb : '',
              postalCode: storeData.postalCode ? storeData.postalCode : '',
              province: storeData.province ? storeData.province : '',
              terrirory: storeData.terrirory ? storeData.terrirory : '',
              zipcode: storeData.zipcode ? storeData.zipcode : '',
              promoCode: state.promocode,
              registerToken: storeData.token,
              practRef: state.practiceId,
            },
            {
              networkParams: { showLoader: false },
            },
          );
          if (response && response.status) {
            //Cookies.remove('paymentToken');
            sessionStorage.clear();
            localStorage.clear();
            navigate('../../login');
          }
          return callback(null, response);
        } else {
          navigate('../../login');
        }
      } catch (e) {
        console.log(e);
        return callback(e, null);
      } finally {
        // sessionStorage.clear();
        // localStorage.clear();
      }
    },
    [paymentToken, navigate],
  );

  const savePricingDetails = async (data, callback) => {
    try {
      let store = sessionStorage.getItem('store')
        ? JSON.parse(sessionStorage.getItem('store'))
        : {};

      const selectedProductFromStorage = sessionStorage.getItem(SELECTED_PRODUCT);
      const parsedSelectedProduct = selectedProductFromStorage
        ? JSON.parse(selectedProductFromStorage)
        : null;

      const practiceId = store.practiceId;
      const practiceName = store.practiceName;
      const emailId = store.contactEmailId;

      const subscriptionPlan = parsedSelectedProduct
        ? parsedSelectedProduct.obj_name
        : data.selectedProduct
        ? data.selectedProduct.obj_name
        : null;

      if (data && store.token) {
        let responseOfPackge = await ajax.post(
          'save-pricing-into-practice',
          {
            packageName: data.package,
            token: store.token,
            practRef: store.practiceId,
            subscriptionPlan: subscriptionPlan,
          },
          {
            networkParams: { showLoader: false },
          },
        );

        if (
          responseOfPackge.message ==
          'The promo code entered is not linked with a promotion.Please try again.'
        ) {
          return callback(responseOfPackge, null);
        }

        if (responseOfPackge && responseOfPackge.status) {
          const coupon = data.coupon;
          const formattedCoupon = coupon ? coupon.toLowerCase() : '';

          if (formattedCoupon !== LIFETIME_COUPON) {
            const isFreeTrialImplemented = await checkIfTrialImplemented();
            const stripeCustomer = await createPracticeStripeCustomer();

            const createSubscriptionPayload = {
              priceId: parsedSelectedProduct.id,
              customerId: stripeCustomer.data.customerId,
              practiceId: practiceId,
              practiceName: practiceName,
              promoCode: coupon,
            };

            if (isFreeTrialImplemented || coupon) {
              const setupIntent = await createSetupIntent(stripeCustomer.data.customerId);
              const setupIntentClientSecret = setupIntent.data.clientSecret;
              sessionStorage.setItem(SETUP_INTENT_CLIENT_SECRET, setupIntentClientSecret);
              sessionStorage.setItem(
                CREATE_SUBSCRIPTION_PAYLOAD,
                JSON.stringify(createSubscriptionPayload),
              );
            } else {
              const stripeSubscription = await createStripeSubscription(createSubscriptionPayload);
              sessionStorage.setItem(
                STRIPE_SUBSCRIPTION_ID,
                stripeSubscription.data.subscriptionId,
              );
              const paymentIntentClientSecret = stripeSubscription.data.paymentIntentClientSecret;

              if (paymentIntentClientSecret) {
                sessionStorage.setItem(PAYMENT_INTENT_CLIENT_SECRET, paymentIntentClientSecret);
              }
            }

            sessionStorage.setItem(IS_FREE_TRIAL_IMPLEMENTED, isFreeTrialImplemented);

            navigate('../payment', { state: { ...state, ...data } });
          } else {
            await handleLifetimeSubscription(emailId, practiceId, subscriptionPlan);
            navigate('../payment-success');
          }
        } else {
          return callback(responseOfPackge, null);
        }
      } else {
        navigate('../../login');
      }
    } catch (e) {
      console.log(e);
      return callback(e, null);
    }
  };

  const populatePaymentTypes = useCallback(async (data, callback) => {
    try {
      let result = {};
      result = await ajax.post(
        'get-payment-types',
        {},
        {
          networkParams: { showLoader: false },
        },
      );

      if (!result.status) {
        return callback(result, null);
      }
      return callback(null, result);
    } catch (e) {
      console.log(e);
      return callback(e, null);
    }
  }, []);

  const makeLastFailPayment = useCallback(async (data, callback) => {
    console.log('makeLastFailPayment');
    callback(null, null);
    const userCookie = Cookies.get('user', { domain: rootSubdomain });
    const key = process.env.REACT_APP_ENCRYPTION_KEY;
    let bytes = CryptoJS.AES.decrypt(userCookie, key);
    const userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    const id = userData._id;
    const practiceId = userData.practiceId;
    const emailId = userData.emailId;
    const latestCampaignPaymentFailed = userData.latestCampaignPaymentFailed;
    try {
      let date = data.expirationDate.split('/');
      let expDate = date[0] + '20' + date[1];
      let result = await ajax.post(
        'make-campaign-payment',
        {
          emailId: emailId,
          cvv: data.cvv,
          cardNumber: data.cardnumber,
          cardType: data.cardType,
          expDate: expDate,
          practiceId: practiceId,
          paymentType: 'cc',
          latestCampaignPaymentFailed: latestCampaignPaymentFailed,
        },
        {
          networkParams: { showLoader: false },
        },
      );
      const key = process.env.REACT_APP_ENCRYPTION_KEY;
      const user = Cookies.get('user');
      let bytes = CryptoJS.AES.decrypt(user, key);
      const parsedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // parsedUser.firstPaymentMade = true;
      parsedUser.latestCampaignPaymentFailed = false;
      let userData = CryptoJS.AES.encrypt(JSON.stringify(parsedUser), key).toString();
      Cookies.set('user', userData);

      setTimeout(function () {
        window.location.reload();
      }, 1500);

      return callback(null, result);
    } catch (error) {
      return callback(null, null);
    }
  });

  const createPracticeStripeCustomer = async () => {
    try {
      let storeData = sessionStorage.getItem('store')
        ? JSON.parse(sessionStorage.getItem('store'))
        : {};

      const result = await ajax.post('stripe/customer', {
        token: storeData.token,
        contactEmailId: storeData.contactEmailId,
      });

      return result;
    } catch (e) {
      console.log(e);
    }
  };

  const createStripeSubscription = async ({
    customerId,
    practiceId,
    practiceName,
    promoCode,
    priceId,
  }) => {
    try {
      const result = await ajax.post('stripe/subscription', {
        customerId: customerId,
        priceId: priceId,
        coupon: promoCode,
        metadata: {
          practiceId: practiceId,
          practiceName: practiceName,
        },
      });

      return result;
    } catch (e) {
      console.log(e);
    }
  };

  const checkIfTrialImplemented = async () => {
    try {
      let isImplemented = false;
      const result = await ajax.post('check-if-free-trial-implemented');
      if (result.status && result.data) {
        isImplemented = result.data.isFreeTrialImplemented;
      }

      return isImplemented;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const createSetupIntent = async (customerId) => {
    try {
      const result = await ajax.post('stripe/setupIntent/create', {
        customerId: customerId,
      });

      return result;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const handleLifetimeSubscription = async (emailId, practiceId, subscriptionPlan) => {
    try {
      const result = await ajax.post('lifetime-subscription', {
        emailId: emailId,
        practiceId: practiceId,
        subscriptionPlan: subscriptionPlan,
      });
      return result;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const getPriceDetails = useCallback(async (values, callback) => {
    try {
      const priceId = values;
      if (priceId) {
        const result = await ajax.post('stripe/price/details', {
          priceId: priceId,
        });

        return callback(null, result.data);
      }
    } catch (e) {
      console.log(e);
      return callback(e, null);
    }
  }, []);

  const navigateToAddPaymentV2 = useCallback(async (values, callback) => {
    try {
      if (values) {
        const code = values.promocode;

        if (code) {
          const promoCode = await validatePromo(code);
          if (promoCode.status) {
            values.coupon = promoCode.data.coupon;
          }
        }
        sessionStorage.setItem('pricing', JSON.stringify(values));
        savePricingDetailsV2(values, (err, data) => {
          if (err) {
            return callback(err, null);
          }
        });
      }
    } catch (e) {
      console.log(e);
      return callback(e, null);
    }
  }, []);

  const savePricingDetailsV2 = async (data, callback) => {
    try {
      let store = sessionStorage.getItem('store')
        ? JSON.parse(sessionStorage.getItem('store'))
        : {};

      const selectedProductFromStorage = sessionStorage.getItem(SELECTED_PRODUCT);
      const parsedSelectedProduct = selectedProductFromStorage
        ? JSON.parse(selectedProductFromStorage)
        : null;

      const practiceId = store.practiceId;
      const practiceName = store.practiceName;
      const emailId = store.contactEmailId;

      const subscriptionPlan = parsedSelectedProduct
        ? parsedSelectedProduct.obj_name
        : data.selectedProduct
        ? data.selectedProduct.obj_name
        : null;

      if (data) {
        const coupon = data.coupon;
        const formattedCoupon = coupon ? coupon.toLowerCase() : '';

        if (formattedCoupon !== LIFETIME_COUPON) {
          const isFreeTrialImplemented = await checkIfTrialImplemented();
          const stripeCustomer = await createPracticeStripeCustomer();

          const createSubscriptionPayload = {
            priceId: parsedSelectedProduct.id,
            customerId: stripeCustomer.data.customerId,
            practiceId: practiceId,
            practiceName: practiceName,
            promoCode: coupon,
          };

          if (isFreeTrialImplemented || coupon) {
            const setupIntent = await createSetupIntent(stripeCustomer.data.customerId);
            const setupIntentClientSecret = setupIntent.data.clientSecret;
            sessionStorage.setItem(SETUP_INTENT_CLIENT_SECRET, setupIntentClientSecret);
            sessionStorage.setItem(
              CREATE_SUBSCRIPTION_PAYLOAD,
              JSON.stringify(createSubscriptionPayload),
            );
          } else {
            const stripeSubscription = await createStripeSubscription(createSubscriptionPayload);
            sessionStorage.setItem(STRIPE_SUBSCRIPTION_ID, stripeSubscription.data.subscriptionId);
            const paymentIntentClientSecret = stripeSubscription.data.paymentIntentClientSecret;

            if (paymentIntentClientSecret) {
              sessionStorage.setItem(PAYMENT_INTENT_CLIENT_SECRET, paymentIntentClientSecret);
            }
          }

          sessionStorage.setItem(IS_FREE_TRIAL_IMPLEMENTED, isFreeTrialImplemented);

          Cookies.remove('token', { domain: rootSubdomain });
          Cookies.remove('hosttype', { domain: rootSubdomain });
          Cookies.remove('user', { domain: rootSubdomain });
          Cookies.remove('path', { domain: rootSubdomain });
          navigate('/sign-up/payment', { state: { ...state, ...data } }, { replace: true });
          window.location.reload();
        } else {
          await handleLifetimeSubscription(emailId, practiceId, subscriptionPlan);
          navigate('/sign-up/payment-success');
        }
      } else {
        navigate('../../login');
      }
    } catch (e) {
      console.log(e);
      return callback(e, null);
    }
  };

  return {
    navigateToAddPayment,
    navigateToPricing,
    generateTokenForPracticePayment,
    makePaymentForPractice,
    verifyPaymentAlreadyDone,
    savePricingDetails,
    populatePaymentTypes,
    makeLastFailPayment,
    createStripeSubscription,
    getPriceDetails,
    navigateToAddPaymentV2,
    savePricingDetailsV2,
  };
};
