/* eslint-disable */
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import ContextDevTool from 'react-context-devtool';
import taskReducer, { initialState } from '../reducers/taskReducer';

export const TaskContext = createContext();

const TaskProvider = ({ children }) => {
  const useTaskState = useReducer(taskReducer, initialState);
  return (
    <TaskContext.Provider value={[...useTaskState]}>
      <ContextDevTool context={TaskContext} id="TaskContext" displayName="Task Context" />
      {children}
    </TaskContext.Provider>
  );
};

TaskProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default TaskProvider;
