/* eslint-disable  */
import crypto from 'crypto';
let algo = 'aes-128-cbc';
let secKey = process.env.REACT_APP_ENCRYPTION_KEY;
const env = process.env.REACT_APP_ENV;
export const encryptData = (data) => {
  let mykey = crypto.createCipher(algo, secKey);
  let mystr = mykey.update(data, 'utf8', 'hex');
  mystr += mykey.final('hex');
  return mystr;
};

export const decryptData = (encryptData) => {
  let mykey = crypto.createDecipher(algo, secKey);
  let mystr = mykey.update(encryptData, 'hex', 'utf8');
  mystr += mykey.final('utf8');
  return mystr;
};

export const generateSignature = (data) => {
  const secret = process.env.REACT_APP_MLIQ_AUTH_SECRET_KEY;

  const timestamp = Math.floor(Date.now() / 1000);

  const generatedHash = crypto
    .createHmac('SHA256', secret)
    .update(`${timestamp}.${data}`)
    .digest('hex');

  let signature = '';

  if (env === 'prod') {
    signature = `t=${timestamp},te=,li=${generatedHash}`;
  } else {
    signature = `t=${timestamp},te=${generatedHash},li=`;
  }

  return signature;
};

export function encryptAuthPayload(payload) {
  const iv = Buffer.alloc(0);
  const cipher = crypto.createCipheriv('aes-128-ecb', secKey, iv);
  return Buffer.concat([cipher.update(payload), cipher.final()]).toString('base64');
}
