/* eslint-disable global-require */
/* eslint-disable  */

import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import useStyles from '../Auth/Auth.style';
import AuthLogo from '../../components/AuthLogo';
import { useNavigate } from 'react-router';
import Loader from '../../components/Loader/Loader';
import {
  AUTH_PAYLOAD,
  CREATE_SUBSCRIPTION_PAYLOAD,
  STRIPE_SUBSCRIPTION_ID,
} from '../../constants/globalConstants';
import { usePayment } from '../../hooks/usePayments';
import ajax from '../../utils/ajaxHelper';
import { generateSignature } from '../../utils/crypt';
import ReCAPTCHA from 'react-google-recaptcha';
import useAuth from '../../hooks/useAuth';
const PaymentSuccess = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const { createStripeSubscription } = usePayment();

  const { login } = useAuth();

  const [authPayload, setAuthPayload] = useState({});

  const handleContinue = async () => {
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      let payload = {};

      if (authPayload) {
        payload = authPayload;
        payload.recapToken = token;
        await login(payload, null, recaptchaRef.current);
      } else {
        window.location = '/#/login';
      }
    }
  };

  const handleCreateSubscription = async () => {
    try {
      let subscriptionId = '';
      const subscriptionIdFromSessionStorage = sessionStorage.getItem(STRIPE_SUBSCRIPTION_ID);
      const createSubscriptionPayload = sessionStorage.getItem(CREATE_SUBSCRIPTION_PAYLOAD);
      if (createSubscriptionPayload) {
        const payload = JSON.parse(createSubscriptionPayload);
        const subscription = await createStripeSubscription(payload);
        subscriptionId = subscription.data.subscriptionId;
      } else if (subscriptionIdFromSessionStorage) {
        subscriptionId = subscriptionIdFromSessionStorage;
      }

      if (subscriptionId) {
        await finalizePayment(subscriptionId);
      }

      const authPayloadFromStorage = sessionStorage.getItem(AUTH_PAYLOAD);
      setAuthPayload(JSON.parse(authPayloadFromStorage));
      sessionStorage.clear();
      localStorage.clear();

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const finalizePayment = async (subscriptionId) => {
    try {
      const signature = generateSignature(subscriptionId);
      const result = await ajax.post(
        'stripe/payment/finalize',
        {
          subscriptionId: subscriptionId,
        },
        {
          headers: {
            'x-auth-signature': signature,
          },
        },
      );
      return result;
    } catch (error) {
      console.log(err);
      throw err;
    }
  };

  useEffect(() => {
    async function initHandleCreateSubscription() {
      await handleCreateSubscription();
    }

    initHandleCreateSubscription();
  }, []);

  const recaptchaRef = React.useRef();

  return (
    <Choose>
      <When condition={isLoading}>
        <Loader />
      </When>
      <When condition={!isLoading}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          className={classes.success}
        >
          <Grid container spacing={4} direction="column" alignItems="stretch">
            <Grid item xs={12}>
              <Grid container spacing={3} alignItems="center" direction="column">
                <Grid item>
                  <AuthLogo />
                </Grid>
                <Grid item>
                  <Typography variant="h1" className={classes.loginheading}>
                    Subscription Successful
                  </Typography>
                  <Grid item className={classes.subheading}>
                    <Typography variant="h4">Welcome to MyLifestyleIQ!</Typography>
                  </Grid>
                  <Grid item className={classes.subheading}>
                    <Typography variant="h4">You have successfully subscribed</Typography>
                  </Grid>
                  <Grid item className={classes.subheading}>
                    <Typography variant="h4">
                      Get started by inviting your patients to download the MLiQ Mobile App and
                      starting them on the Patient Education Email Campaign.
                    </Typography>
                  </Grid>
                  <Grid item className={classes.subheading}>
                    <Typography variant="h4">
                      If you need any assistance or have any questions, our support team is here for
                      you.
                    </Typography>
                  </Grid>
                  <Grid item className={classes.subheading}>
                    <Button variant="contained" color="primary" onClick={handleContinue}>
                      Continue
                    </Button>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      size={'invisible'}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </When>
    </Choose>
  );
};

export default PaymentSuccess;
