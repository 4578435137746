import React from 'react';
import useStyles from './MainLogo.styles';

const MainLogo = () => {
  const classes = useStyles();
  return (
    <div className={classes.logobox}>
      <img
        className={classes.logo}
        src={require('../../assets/images/logo.png').default}
        alt="Logo"
      />
    </div>
  );
};

export default MainLogo;
