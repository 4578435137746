import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return {
    loaderouter: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    },
  };
});

export default useStyles;
