/* eslint-disable  */

import { makeStyles } from '@material-ui/core/styles';
// import { drawerWidth } from '../../constants/globalConstants';
import bgstyle03 from '../../assets/images/bgstyle03.jpg';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   flexGrow: 1,
  // },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  paperStyleTwo: {
    backgroundColor: 'rgba(255,255,255,1)',
    padding: theme.spacing(2),
    border: '2px solid rgba(162,156,153,0.2)',
    borderRadius: '10px',
  },

  paperWhite: {
    backgroundColor: 'rgba(255,255,255,1)',
    padding: theme.spacing(4),
    border: '2px solid rgba(162,156,153,0.2)',
    borderRadius: '10px',
  },

  paperQuestion: {
    backgroundImage: `url(${bgstyle03})`,
    backgroundPosition: 'top center',
    backgroundColor: 'rgba(255,255,255,1)',
    padding: theme.spacing(2),
    textAlign: 'center',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },

  package: {
    backgroundImage: `url(${bgstyle03})`,
    backgroundPosition: 'top center',
    backgroundColor: 'rgba(255,255,255,1)',
    padding: theme.spacing(2),
    textAlign: 'center',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    cursor: 'pointer',
    minHeight: '460px',
    border: '5px solid #f2efea',
    marginBottom: '45px',
  },

  paperMessage: {
    backgroundColor: 'rgba(255,255,255,1)',
    padding: theme.spacing(1.5),
    border: '2px solid rgba(162,156,153,0.2)',
    borderRadius: '5px',
  },

  paperField: {
    border: '2px solid rgba(162,156,153,0.2)',
    boxShadow: 'none',
    borderRadius: 15,
    backgroundColor: 'rgba(255,255,255,0.8)',
    padding: '18px',
    minHeight: '60px',
  },

  paperHeadingPage: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
    },
  },

  paperHeadingSection: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
  },

  heading: {
    marginTop: theme.spacing(0.2),
    marginBottom: theme.spacing(0),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
  subheading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    // fontStyle: 'Italic',
  },

  selectcreatebtn: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: '#FF7412',
    padding: '10px 5px 10px 0px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#FF7412',
      boxShadow: 'none',
    },
  },

  /* selectdropdown:{
    '& .MuiMenuItem-root':{
      backgroundColor: '#fff',
      color:'red',
    },
    backgroundColor: '#000000',
    '&:hover': {
      backgroundColor: '#fff',
    },
  }, */

  waterMark: {
    paddingTop: '13%',
  },

  paperWhiteTitle: {
    color: '#61B3CD !important',
    fontWeight: 'bold',
    paddingBottom: '10px',
  },
  addListNumber: {
    color: '#61B3CD',
  },
  addlistBg: {
    backgroundColor: 'rgba(162,156,153,0.2)',
    borderRadius: '5px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  addListSpacing: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  backButton: {
    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'white',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  hide: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    direction: 'column',
    justify: 'flex-start',
    // '& > *': {
    //   marginTop: theme.spacing(0.5),
    //   marginBottom: theme.spacing(0.5),
    //   marginLeft: theme.spacing(2),
    //   marginRight: theme.spacing(2),
    // },
  },
  small: {
    width: 52,
    height: 52,
    backgroundColor: '#61B3CD !important',
  },
  large: {
    width: '100px !important',
    height: '100px !important',
    backgroundColor: '#61B3CD !important',
  },
  medium: {
    width: '70px !important',
    height: '70px !important',
    backgroundColor: '#61B3CD !important',
  },
  logout: {
    fontSize: 18,
    fontWeight: 700,
    padding: '5px 30px',
  },

  multilinestyle: {
    '& .MuiInputLabel-formControl': {
      marginLeft: theme.spacing(0),
      top: '-5px',
    },
    '& .MuiInputLabel-shrink': {
      top: '0',
    },
    '& .Mui-focused': {
      top: '0',
    },
    '& label + .MuiInput-formControl': {
      marginTop: '26px',
    },
  },

  disabledBg: {
    backgroundColor: 'rgba(227, 226, 225, 1)',
  },

  circleIconBox: {
    backgroundColor: '#ffffff',
    border: '4px solid rgba(243,239,234,1)',
    borderRadius: '100px',
    height: '130px',
    width: '130px',
    boxShadow: '0px 0px 10px #eae9e8',
    display: 'inline-flex',
    verticalAlign: 'middle',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // Overrides style - modal dialog
  closeButton: {
    right: '8px',
    top: '8px',
    textAlign: 'right',
  },

  // material Data Table

  tbAlert: {
    fontSize: '12px',
    backgroundColor: '#61B3CD',
    color: '#ffffff',
    borderRadius: '5px',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  materialDT: {
    // backgroundColor: '#ffffff',
    '& .MuiTableCell-root:last-child > div ': { display: 'block !important', textAlign: 'center' },
  },

  actions: {
    // backgroundColor: '#ffffff',
    display: 'block !important',
    textAlign: 'center',
  },

  materialHighlight: {
    backgroundColor: '#e2ddd8',
  },
  // Chat
  chat: {
    Position: 'relative',
    height: '450px',
    overflowY: 'scroll',
  },

  chatLeft: {
    textAlign: 'left',
  },
  chatCenter: {
    textAlign: 'center',
  },
  chatRight: {
    textAlign: 'right',
  },

  chatLeftBox: {
    backgroundColor: 'rgba(255,255,255,1)',
    padding: theme.spacing(2),
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    fontSize: '16px',
    color: '#4B403A',
    display: 'inline-flex',
    boxShadow: '0px 2px 2px rgba(29,25,23,0.09)',
    marginTop: '5px',
    marginBottom: '10px',
    // width: '80%',
  },

  chatRightBox: {
    backgroundColor: 'rgba(127,193,214,1)',
    padding: theme.spacing(2),
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    fontSize: '16px',
    color: '#ffffff',
    // width: 'fit-content',
    boxShadow: '0px 2px 2px rgba(29,25,23,0.09)',
    marginTop: '5px',
    marginBottom: '10px',
    display: 'inline-flex',
    // width: '80%',
    textAlign: 'left',
  },

  chatProW: {
    width: '80px',
    flex: 'inherit',
  },

  profileW: {
    width: '150px !important',
    flex: 'inherit !important',
  },

  selectderror: {
    color: '#f44336',
    marginLeft: '12px',
    marginRight: '12px',
  },

  // Chip

  selectedTags: {
    color: '#4B403A',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    margin: '10px 5px 0px 5px',
  },

  responsedropzonestyle: {
    '& .makeStyles-dropzonestyle-101': {
      padding: '20px',
      '& .makeStyles-dropzonetitle-102': {
        display: 'flex',
        '& .makeStyles-dropzonetext-103': {
          width: '140px',
          lineHeight: '16px',
        },
      },
    },
  },

  warning: {
    border: '2px solid rgba(162,156,153,0.2)',
    borderRadius: '5px',
    backgroundColor: '#61B3CD',
    '& .MuiTypography-h3': {
      color: '#fff',
    },
  },
  warningicon: {
    display: 'flex',
    backgroundColor: '#fff',
    color: '#FF7412',
    borderRadius: '50%',
    padding: '5px',
  },
  whitebutton: {
    backgroundColor: '#fff',
    color: '#FF7412',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },

  container1: {
    display: 'flex',
    padding: '10px 20px',
    backgroundColor: '#fff',
    maxWidth: '600px',
    margin: '12px auto',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '20px',
    border: '1px solid #ddd',
  },

  content: {
    display: 'flex',
    alignItems: 'center',
  },

  btn: {
    color: '#fff',
    textAlign: 'center',
    width: '30px',
    height: '40px',
    lineHeight: '40px',
    border: 'none',
  },

  resourceItem: {
    '& img': {
      boxShadow: '0 2px 10px 0 rgb(0 0 0 / 15%)',
      marginBottom: theme.spacing(4),
    },
    '& video': {
      boxShadow: '0 2px 10px 0 rgb(0 0 0 / 15%)',
      marginBottom: theme.spacing(4),
    },
    '& iframe': {
      boxShadow: '0 2px 10px 0 rgb(0 0 0 / 15%)',
      marginBottom: theme.spacing(2),
    },
  },
}));
export default useStyles;
