/* eslint-disable */
import React, { lazy /* , useEffect */ } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader/Loader';
import MainLogo from '../components/MainLogo/MainLogo';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

import { getRootSubdomain } from '../utils';
const rootSubdomain = getRootSubdomain(window.location.hostname);

const Auth = lazy(() => import('../screens/Auth'));
const Login = lazy(() => import('../screens/Auth/Login'));
const Splash = lazy(() => import('../screens/Auth/Splash'));
const ForgotPassword = lazy(() => import('../screens/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../screens/Auth/ResetPassword'));
const MobileResetPassword = lazy(() => import('../screens/Auth/MobileResetPassword'));
const Success = lazy(() => import('../screens/Auth/Success'));
const SetPassword = lazy(() => import('../screens/Auth/SetPassword'));
const TwoFactorAuthentication = lazy(() => import('../screens/Auth/TwoFactorAuthentication'));

const PublicRoute = ({ as: Component, ...props }) => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [auth, setAuth] = React.useState(false);
  const userCookie = Cookies.get('user', { domain: rootSubdomain });
  let userType = '';
  const key = process.env.REACT_APP_ENCRYPTION_KEY;
  React.useEffect(() => {
    if (cookies.token) {
      //const userData = JSON.parse(atob(userCookie));
      let bytes = CryptoJS.AES.decrypt(userCookie, key);
      const userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      console.log('userData11');
      console.log(typeof userData);
      console.log(userData);
      userType = (userData.userType && userData.userType.type) || null;

      if (userType == 'uberadmin') {
        navigate('../app');
      } else {
        navigate('../app/practice-dashboard');
        Cookies.set('path', 'practice-dashboard');
      }
      setAuth(false);
    } else {
      setAuth(true);
      Cookies.remove('path');
    }
  }, [cookies.token, navigate]);

  return auth ? <Component {...props} /> : <Loader />;
};

const authNav = [
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: 'reset-password/:token',
    element: <ResetPassword />,
  },
  {
    path: 'mobile-reset-password/:token',
    element: <MobileResetPassword />,
  },
  {
    path: 'set-password/:registerToken',
    element: <SetPassword />,
  },
  {
    path: 'two-factor-authentication',
    element: <TwoFactorAuthentication />,
  },
  {
    path: 'success',
    element: <Success />,
  },
];

const authRoutes = [
  {
    path: '/',
    element: <PublicRoute as={Auth} />,
    children: authNav,
  },
];

export default authRoutes;
