/* eslint-disable react/prop-types */
/* eslint-disable  */
import React, { lazy /* , useEffect */ } from 'react';
import PaymentSuccess from '../screens/Payment/PaymentSuccess';
// import { useCookies } from 'react-cookie';
// import { useNavigate } from 'react-router-dom';

const PracticeAccount = lazy(() => import('../screens/PracticeAccount'));
const CreateAccount = lazy(() => import('../screens/PracticeAccount/CreateAccount'));
const Pricing = lazy(() => import('../screens/PracticeAccount/Pricing'));
const Payment = lazy(() => import('../screens/PracticeAccount/Payment'));
// const ForgotPassword = lazy(() => import('../screens/PracticeAccount/ForgotPassword'));
const AddUsers = lazy(() => import('../screens/PracticeAccount/AddUsers'));

const PublicRoute = ({ as: Component, ...props }) => {
  // const [cookies] = useCookies();
  // const navigate = useNavigate();

  /* useEffect(() => {
    if (cookies.token) {
      navigate('/#/');
    }
  }, [cookies.token, navigate]); */
  return <Component {...props} />;
};

const practiceAccountNav = [
  {
    path: 'create-account/:registerToken',
    element: <CreateAccount />,
  },
  {
    path: 'addusers',
    element: <AddUsers />,
  },
  {
    path: 'pricing',
    element: <Pricing />,
  },
  {
    path: 'payment',
    element: <Payment />,
  },
  {
    path: 'payment-success',
    element: <PaymentSuccess />,
  },
];

const practiceAccountRoutes = [
  {
    path: '/practice-account',
    element: <PublicRoute as={PracticeAccount} />,
    children: practiceAccountNav,
  },
];

export default practiceAccountRoutes;
