/* eslint-disable react/prop-types */
/* eslint-disable  */
import React, { lazy } from 'react';
import PaymentSuccess from '../screens/Payment/PaymentSuccess';

// Import Screens
const SignUpAccount = lazy(() => import('../screens/SignUpAccount'));
const RegisterAccount = lazy(() => import('../screens/SignUpAccount/SignUp'));
const AddUsers = lazy(() => import('../screens/SignUpAccount/AddUsers'));
const Pricing = lazy(() => import('../screens/SignUpAccount/Pricing'));
const Payment = lazy(() => import('../screens/SignUpAccount/Payment'));

const PublicRoute = ({ as: Component, ...props }) => {
  return <Component {...props} />;
};

const signUpAccountNav = [
  {
    path: 'register',
    element: <RegisterAccount />,
  },
  {
    path: 'addusers',
    element: <AddUsers />,
  },
  {
    path: 'pricing',
    element: <Pricing />,
  },
  {
    path: 'payment',
    element: <Payment />,
  },
  {
    path: 'payment-success',
    element: <PaymentSuccess />,
  },
];

const signUpAccountRoutes = [
  {
    path: '/sign-up',
    element: <PublicRoute as={SignUpAccount} />,
    children: signUpAccountNav,
  },
];

export default signUpAccountRoutes;
