import { makeStyles } from '@material-ui/core/styles';
import bgstyle01 from '../../assets/images/bgstyle01.jpg';
import bgstyle02 from '../../assets/images/bgstyle02.jpg';

const useStyles = makeStyles((theme) => ({
  container: {
    Width: '600px',
    Height: '440px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minHeight: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    height: '100vh',
    backgroundImage: `url(${bgstyle01})`,
    backgroundRepeat: 'repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'auto',
    backgroundAttachment: 'fixed',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  },
  splash: {
    display: 'flex',
    flexGrow: 1,
    height: '100vh',
    backgroundImage: `url(${bgstyle02})`,
    backgroundRepeat: 'repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'auto',
    backgroundAttachment: 'fixed',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  },
  circleIconBox: {
    backgroundColor: '#ffffff',
    border: '4px solid rgba(243,239,234,1)',
    borderRadius: '500px',
    height: '250px',
    width: '250px',
    boxShadow: '0px 0px 10px #eae9e8',
    display: 'inline-flex',
    verticalAlign: 'middle',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    backgroundColor: '#F3EFEA',
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',
    borderRadius: '10px',
    margin: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: '70%',
    minHeight: '50%',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(4),
      padding: theme.spacing(2),
      /* margin: theme.spacing(2), */
      boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',
      backgroundColor: '#F3EFEA',
      /* backgroundColor: '#fafafa', */
      minWidth: '80%',
      minHeight: 'auto',
    },
  },
  wrapper: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  backtologin: {
    color: 'inhert',
    justifyContent: 'center',
    border: '2px solid #4860CF',
    borderRadius: '25px',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  loginheading: {
    marginTop: theme.spacing(0.2),
    marginBottom: theme.spacing(0),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
  subheading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    fontStyle: 'Italic',
  },
  forgotPW: {
    fontWeight: 700,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(2),
    display: 'inline-block',
    position: 'relative',
    color: '#4B403A',
    cursor: 'pointer',
    '&:hover': {
      color: '#FF8C3C',
    },
  },

  AuthenticationCode: {
    textAlign: 'center',
  },

  twoFactorContainer: {
    width: '100%',
  },

  twoFactorHeading: {
    marginTop: theme.spacing(0.2),
    marginBottom: theme.spacing(0),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      width: '100%',
    },
  },
}));

export default useStyles;
