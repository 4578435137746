import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    logobox: {
      display: 'flex',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      // position: 'fixed',
      // top: 0,
      // left: 0,
      zIndex: 9,
    },
    logo: {
      width: 147,
      height: 70,
    },
  };
});

export default useStyles;
