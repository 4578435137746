import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    minHeight: '100vh',
    backgroundColor: '#f3efea',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  oops: {
    width: '100%',
  },
  msg: {
    fontSize: 36,
    fontWeight: 700,
    color: '#A29C99',
  },
  disconnect: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  labeltext: {
    fontSize: 22,
    fontWeight: 700,
    color: '#A29C99',
    textAlign: 'left',
    marginBottom: theme.spacing(0),
    paddingLeft: theme.spacing(5),
  },
  fournotfour: {
    width: '100%',
  },
}));
