/* eslint-disable*/
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  // Color style
  palette: {
    primary: {
      main: '#FF7412',
      contrastText: '#fff',
    },
    secondary: {
      main: '#a29c99',
      contrastText: '#fff',
    },
    tertiary: {
      main: '#61B3CD',
      contrastText: '#fff',
    },
  },
  // Fonts style
  typography: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    h1: {
      fontSize: 22,
      fontWeight: 700,
      color: '#4B403A',
      wordBreak: 'break-word',
    },
    h2: {
      fontSize: 20,
      fontWeight: 500,
      color: '#4B403A',
      wordBreak: 'break-word',
    },
    h3: {
      fontSize: 18,
      fontWeight: 700,
      color: '#4B403A',
      wordBreak: 'break-word',
    },
    h4: {
      fontSize: 16,
      fontWeight: 400,
      color: '#4B403A',
      wordBreak: 'break-word',
    },
    h5: {
      fontSize: 14,
      fontWeight: 400,
      color: '#4B403A',
      wordBreak: 'break-word',
    },
    h6: {
      fontSize: 12,
      fontWeight: 400,
      color: '#4B403A',
      wordBreak: 'break-word',
    },
    // body1: {
    //   /* fontSize: 20,
    //   fontWeight: 400,
    //   color: '#353755', */
    // },
  },
  // Button style
  // forms style
  // Overrides style - button, forms

  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#f3efea',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },

    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: '10px solid rgba(243, 239, 234, 1)',
        boxShadow: '3px 0 5px 0 rgba(0,0,0,0.1)',
      },
    },

    MuiDivider: {
      root: {
        marginTop: '10px',
        marginBottom: '10px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        height: 'auto',
        background: 'none',
      },
    },

    MuiButton: {
      root: {
        borderRadius: 10,
        padding: '12px 35px',
        fontSize: 14,
        fontWeight: 700,
        lineHeight: 1,
        minWidth: 160,
        '@media (max-width:960px)': {
          borderRadius: 10,
          padding: '12px 35px',
          fontSize: 14,
        },
      },
      contained: {
        color: '#FFFFFF',
        backgroundColor: 'rgba(162, 156, 153, 1)',
        minWidth: '120px',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: 'rgba(72, 96, 207, 0.1)',
          color: '#3C3F61',
        },
        '&:active': {
          backgroundColor: 'rgba(72, 96, 207, 0.1)',
          color: '#3C3F61',
        },
      },
      gutters: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
        color: '#96A1BC',
        lineHeight: 1.5,
        '&.$Mui-disabled': {
          color: '#000',
        },
      },
      shrink: {
        fontSize: 16,
        color: '#4B403A',
        fontWeight: 700,
      },
    },
    MuiListItem: {
      button: {
        /* '&:hover': {
          backgroundColor: '#ffffff!important',
          
        }, */
      },
    },

    MuiOutlinedInput: {
      root: {
        border: '2px solid rgba(162,156,153,0.2)',
        borderRadius: 15,
        backgroundColor: 'rgba(255,255,255,0.8)',
      },
      inputMultiline: {
        borderRadius: 15,
        padding: 15,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
      },
      multiline: {
        padding: 0,
      },
      notchedOutline: {
        borderColor: 'transparent',
      },
    },

    MuiAutocomplete: {
      option: {
        '&:hover': {
          backgroundColor: '#ffffff',
        },
        '&$checked': {
          backgroundColor: '#4B403A',
        },
      },
    },

    // Mui: {
    //   Disabled: {
    //     fontSize: 56,
    //     backgroundColor: '#000',
    //   },
    // },

    MuiInputBase: {
      input: {
        fontSize: 16,
        color: '#4B403A',
        backgroundColor: '#fff',
        borderRadius: 15,
        fontWeight: 400,
        padding: 10,
        '&.$Mui-disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
      },
      multiline: {
        // border: '2px solid rgba(162,156,153,0.2)',
        // borderBottom: 'none',
        fontSize: 16,
        color: '#4B403A',
        backgroundColor: '#fff',
        borderRadius: 15,
        fontWeight: 400,
        // '&.$Mui-disabled': {
        //   backgroundColor: 'rgba(0, 0, 0, 0.1)',
        // },
      },
    },
    MuiInput: {
      underline: {
        '&&&&:before': {
          borderBottom: '0px solid rgba(187, 187, 187, 1)',
        },
        '&&&&:after': {
          borderBottom: '0px solid rgba(187, 187, 187, 1)',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 18,
        fontWeight: 400,
        color: '#4B403A',
      },
    },
    // MuiDialogContent: {
    //   root: {
    //     overflow: 'auto',
    //   },
    // },

    MuiSelect: {
      icon: {
        color: '#61B3CD',
        right: '5px',
      },
      outlined: {
        borderRadius: 15,
      },
      select: {
        borderRadius: 15,
        padding: '18.5px 14px',
        border: '1px solid #c4c4c4',
        boxShadow: '0px 0px 5px rgba(162,156,153,0.7)',
        '&:focus': {
          borderRadius: 15,
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: '#61B3CD',
      },
    },
    MuiIconButton: {
      root: {
        color: '#61B3CD',
      },
      colorInherit: {
        color: '#FF7412',
      },
      colorSecondary: {
        color: '#ffffff',
      },
    },

    MuiAvatar: {
      img: {
        width: 'auto',
        height: 'auto',
      },
    },

    // Right Panel
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: '#4B403A',
        color: '#ffffff',
      },
    },

    MuiExpansionPanelDetails: {
      root: {
        padding: '10px 0px 10px 0px',
      },
    },

    // Datepicker
    MuiPickersBasePicker: {
      pickerView: {
        width: 'auto',
        height: 'auto',
        maxWidth: 'auto',
        minWidth: 'auto',
        minHeight: 'auto',
        padding: '10px 20px 10px 20px',
      },
    },

    MuiPickersCalendarHeader: {
      switchHeader: {
        marginTop: '15px',
        marginBottom: '30px',
      },
    },

    // Chip

    MuiChip: {
      root: {
        boxShadow: '0px 0 5px rgba(0,0,0,0.3)',
      },
      sizeSmall: {
        fontSize: '16px',
      },
    },

    // Switch
    MuiSwitch: {
      root: {
        width: 41,
        height: 22,
        padding: 0,
        display: 'flex',
      },

      switchBase: {
        padding: 4,
        color: '#A29C99',
        '&$checked': {
          transform: 'translateX(20px)',
          color: '#FF7412!important',

          '& + $track': {
            opacity: 1,
            backgroundColor: '#fff!important',
            borderColor: '#FF7412',
            color: '#FF7412!important',
          },
        },
      },

      thumb: {
        width: 13,
        height: 13,
        boxShadow: 'none',
      },

      track: {
        border: '2px solid #A29C99',
        borderRadius: 30 / 2,
        opacity: 1,
        backgroundColor: '#fff',
      },
    },

    // Overrides style - modal dialog

    MuiDialogTitle: {
      root: {
        margin: 0,
        padding: '16px',
        textAlign: 'center',
      },
    },

    MuiDialogContent: {
      root: {
        overflow: 'auto',
        paddingTop: '0px',
        paddingBottom: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    },

    MuiDialogActions: {
      spacing: {
        paddingTop: '16px',
        paddingBottom: '30px',
        paddingLeft: '16px',
        paddingRight: '16px',
        justifyContent: 'center',
      },
    },

    // Chat

    MuiListItemText: {
      root: {
        width: 'inherit',
      },
      primary: {
        fontSize: '13px',
        fontWeight: 'bold',
      },
    },

    // Overrides style - material ui data table

    MuiTableCell: {
      root: {
        borderBottom: '8px solid rgba(243, 239, 234, 1)',
        wordBreak: 'break-word',
        padding: '12px',
        '&:last-child': {
          paddingRight: '12px!important',
          width: '96px!important',
        },
      },

      head: {
        backgroundColor: '#e2ddd8!important',
        color: '#4B403A',
        fontWeight: 'bold',
        fontSize: 16,
        whiteSpace: 'nowrap',
      },
    },

    MuiTableRow: {
      root: {
        backgroundColor: 'rgba(255,255,255,1)',
        color: '#4B403A',
        fontSize: 16,
        '&:hover': {
          backgroundColor: '#e2ddd8',
          color: '#4B403A',
        },
      },
      footer: {
        backgroundColor: '#f3efea',
        '&:hover': {
          backgroundColor: '#f3efea',
        },
      },
    },

    MuiTablePagination: {
      select: {
        backgroundColor: 'transparent',
        border: '0px solid #c4c4c4',
        boxShadow: '0px 0px 0px rgba(162,156,153,0.7)',
        lineHeight: '20px',
      },

      root: {
        '&:last-child': {
          width: '100%!important',
        },
      },
    },
  },
});

export default theme;
