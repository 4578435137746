/* eslint-disable  */
import Cookies from 'js-cookie';
import { roles } from '../constants/globalConstants';

export const getHostType = (roleId) => {
  switch (roleId) {
    case 'admin':
      return roles.Company;
    case 'user':
      return roles.Client;
    default:
      return roles.Uber;
  }
};

export const getCompanyId = () => {
  const user = Cookies.get('user', { domain: getRootSubdomain(window.location.hostname) });
  let companyId;
  if (user) {
    const parsedUser = JSON.parse(atob(user));
    companyId = parsedUser.companyId;
  }
  return companyId;
};

export const showErrIfTouched = (key, errors, touched) => {
  return touched[key] && errors[key];
};
export const showErrIfTouchedAutocomplete = (key, errors, touched) => {
  return touched && errors[key];
};

export const isFile = (input) => 'File' in window && input instanceof File;

export const isEmpty = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const checkForSpaceAndEntry = (string) => {
  return /^ *$/.test(string);
};

export const postalCodeFormat = (input, props = '', setFieldValue = '', fieldName = '') => {
  input = input.replace('-', '');
  // Trim the remaining input to ten characters, to preserve phone number format
  input = input.substring(0, 6);
  // Based upon the length of the string, we add formatting as necessary
  var size = input.length;

  if (size < 4) {
    input = input;
  } else {
    input = input.substring(0, 3) + '-' + input.substring(3, 6);
  }
  //input = input.substring(0, 3) + '-' + input.substring(3, 6) ;
  // formik.current.setFieldValue(field, input);
  // setValues({ ...values, [field]: input });
  if (fieldName) {
    setFieldValue(fieldName, input);
  }
  if (props) {
    props.onChange(input);
  }
};

/**
 * Accepts a domain string from e.g. `window.location.hostname`, checks for a subdomain and removes it if present.
 * @param {string} hostname - The domain to remove the subdomain from.
 * @returns {string} A domain string without a subdomain with a `.` at the start.
 */
export const getRootSubdomain = (hostname) => {
  if (process.env.NODE_ENV !== 'production') {
    return 'localhost';
  }
  const match = hostname.match(/^([^.]+\.)?(.+\..+)$/);
  return match ? `.${match[2]}` : `.${hostname}`;
};
