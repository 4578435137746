export const drawerWidth = 210;

export const roles = {
  Uber: 'Uber',
  Company: 'Company',
  Client: 'Client',
};

export const a = 'a';

// Storage Keys
export const STRIPE_CLIENT_SECRET = 'stripe_client_secret';
export const PAYMENT_INTENT_CLIENT_SECRET = 'payment_intent_client_secret';
export const SETUP_INTENT_CLIENT_SECRET = 'setup_intent_client_secret';
export const IS_FREE_TRIAL_IMPLEMENTED = 'isFreeTrialImplemented';
export const CREATE_SUBSCRIPTION_PAYLOAD = 'create_subscription_payload';
export const STRIPE_SUBSCRIPTION_ID = 'stripe_subscription_id';
export const LIFETIME_COUPON = 'lifetime';
export const AUTH_PAYLOAD = 'auth_payload';
export const PRICING_STATE = 'pricing_state';
export const STATES_DATA = 'states_data';
export const SELECTED_PRODUCT = 'selected_product';
