/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable import/named */
/* eslint-disable  */
import Cookies from 'js-cookie';
import React, { lazy } from 'react';
//import crypto from "crypto";
import CryptoJS from 'crypto-js';
import authRoutes from './authRoutes';
// import settingsNav from './settingsNav';

import { getRootSubdomain } from '../utils';
const rootSubdomain = getRootSubdomain(window.location.hostname);

import { ReactComponent as TestIcon } from '../assets/svgIcons/sidebarNav/testicon.svg';
import { ReactComponent as ReferredByIcon } from '../assets/svgIcons/sidebarNav/referred-by.svg';
import { ReactComponent as ReferredByActive } from '../assets/svgIcons/sidebarNav/referred-by-active.svg';
import { ReactComponent as TestIconActive } from '../assets/svgIcons/sidebarNav/testicon-active.svg';
import { ReactComponent as PracticeIcon } from '../assets/svgIcons/sidebarNav/practices-whitesvg.svg';
import { ReactComponent as PracticeIconActive } from '../assets/svgIcons/sidebarNav/practices-orangesvg.svg';
import { ReactComponent as NeuroIcon } from '../assets/svgIcons/sidebarNav/neuro-whitesvg.svg';
import { ReactComponent as NeuroIconActive } from '../assets/svgIcons/sidebarNav/neuro-orangesvg.svg';
import { ReactComponent as ClinicalIcon } from '../assets/svgIcons/sidebarNav/clinical-whitesvg.svg';
import { ReactComponent as ClinicalIconActive } from '../assets/svgIcons/sidebarNav/clinical-orangesvg.svg';
import { ReactComponent as SurveyIcon } from '../assets/svgIcons/sidebarNav/survey-whitesvg.svg';
import { ReactComponent as SurveyIconActive } from '../assets/svgIcons/sidebarNav/survey-orangesvg.svg';
import { ReactComponent as PatientIcon } from '../assets/svgIcons/sidebarNav/patients-whitesvg.svg';
import { ReactComponent as PatientIconActive } from '../assets/svgIcons/sidebarNav/patients-orangesvg.svg';
import { ReactComponent as MessagesIcon } from '../assets/svgIcons/sidebarNav/messages-whitesvg.svg';
import { ReactComponent as MessagesIconActive } from '../assets/svgIcons/sidebarNav/messages-orangesvg.svg';
import { ReactComponent as SettingsIcon } from '../assets/svgIcons/sidebarNav/settings-whitesvg.svg';
import { ReactComponent as SettingsIconActive } from '../assets/svgIcons/sidebarNav/settings-orangesvg.svg';
import { ReactComponent as ResourceOrange } from '../assets/svgIcons/sidebarNav/ResourceOrange.svg';
import { ReactComponent as ResourceWhite } from '../assets/svgIcons/sidebarNav/ResourceWhite.svg';
import { ReactComponent as Resource } from '../assets/svgIcons/sidebarNav/survey-whitesvg.svg';
import { ReactComponent as ReferralIcon } from '../assets/svgIcons/sidebarNav/blueReferral.svg';
import { ReactComponent as ReferralActiveIcon } from '../assets/svgIcons/sidebarNav/orangeReferral.svg';
import { ReactComponent as CampaignIcon } from '../assets/svgIcons/sidebarNav/campaign.svg';
import { ReactComponent as CampaignActiveIcon } from '../assets/svgIcons/sidebarNav/campaignSelected.svg';
// import { ReactComponent as AppointmentIcon } from '../assets/svgIcons/sidebarNav/appointment.svg';
// import { ReactComponent as CalendartIcon } from '../assets/svgIcons/sidebarNav/calendar.svg';
// import { ReactComponent as ClassestIcon } from '../assets/svgIcons/sidebarNav/classes.svg';
// import { ReactComponent as ClientstIcon } from '../assets/svgIcons/sidebarNav/clients.svg';
// import { ReactComponent as ReportstIcon } from '../assets/svgIcons/sidebarNav/reports.svg';
// import { ReactComponent as SalesIcon } from '../assets/svgIcons/sidebarNav/sales.svg';
// import { ReactComponent as StaffIcon } from '../assets/svgIcons/sidebarNav/staff.svg';
// import { ReactComponent as StudiosIcon } from '../assets/svgIcons/sidebarNav/studios.svg';
// import { ReactComponent as SettingsIcon } from '../assets/svgIcons/sidebarNav/settings.svg';

// const TimeZoneProvider = lazy(() => import('../contexts/timeZoneContext'));
// // const CompaniesProvider = lazy(() => import('../contexts/companiesContext'));
// const CompanyProvider = lazy(() => import('../contexts/companyContext'));
// const ClassCategoriesProvider = lazy(() => import('../contexts/classCategoriesContext'));
// const RevenueCategoriesProvider = lazy(() => import('../contexts/revenueCategoriesContext'));
// const TaxDetailsProvider = lazy(() => import('../contexts/taxDetailsContext'));
// const StateProvider = lazy(() => import('../contexts/stateContext'));
// const CancelPolicyProvider = lazy(() => import('../contexts/cancelPolicyContex'));

let userType = '';
let subscriptionPlan = 'corporate';
let appNav = [];
const AddPractices = lazy(() => import('../screens/Main/AddPractices'));
const ComingSoon = lazy(() => import('../screens/Main/ComingSoon'));
const PracticeListView = lazy(() => import('../screens/Main/PracticeListView'));
const AddPracticeUsers = lazy(() => import('../screens/Main/AddPracticeUsers'));
const BulkAddPatients = lazy(() => import('../screens/Main/BulkAddPatients'));
const AddPatients = lazy(() => import('../screens/Main/AddPatients'));
const ClinicalAssessmentDetail = lazy(() => import('../screens/Main/ClinicalAssessmentDetail'));
const PracticeDetail = lazy(() => import('../screens/Main/PracticeDetail'));
const PracticeDetailPage = lazy(() => import('../screens/Main/PracticeDetailAdmin'));
const MyMessagesPractice = lazy(() => import('../screens/Main/MyMessagesPractice'));
const OnBoardingServey = lazy(() => import('../screens/Main/OnBoardingServey'));
const MyPatients = lazy(() => import('../screens/Main/MyPatients'));
const Referrals = lazy(() => import('../screens/Main/Refferals'));
const ClinicalAssessments = lazy(() => import('../screens/Main/ClinicalAssessments'));
const ProgramDetail = lazy(() => import('../screens/Main/ProgramDetail'));
const CreateNewTask = lazy(() => import('../screens/Main/CreateNewTask'));
const Message = lazy(() => import('../screens/Main/Message'));
const NewMessage = lazy(() => import('../screens/Main/NewMessage'));
const CreateNewQuestion = lazy(() => import('../screens/Main/CreateNewQuestion'));
const PracticeUserProfile = lazy(() => import('../screens/Main/PracticeUserProfile'));
const EditPatient = lazy(() => import('../screens/Main/EditPatient'));
const PracticeDashboard = lazy(() => import('../screens/Main/PracticeDashboard'));
const CreateNeuroQuestions = lazy(() => import('../screens/Main/CreateNeuroQuestions'));
const NeuroAssessments = lazy(() => import('../screens/Main/NeuroAssessments'));
const NeuroScoreDetail = lazy(() => import('../screens/Main/NeuroScoreDetail'));
const OnboardingSurveyDetail = lazy(() => import('../screens/Main/OnboardingSurveyDetail'));
const AdminResources = lazy(() => import('../screens/Main/AdminResources'));
const WaterMark = lazy(() => import('../screens/Main/WaterMark'));
const CreateNewCourse = lazy(() => import('../screens/Main/CreateNewCourse'));
// const NeuroDrillDown = lazy(() => import('../screens/Main/NeuroDrillDown'));
const CreateOnBoardingSurveyQuestion = lazy(() =>
  import('../screens/Main/CreateOnBoardingSurveyQuestion'),
);
const PatientPricingDetails = lazy(() =>
  import('../screens/Main/PackageDetails/PatientPricingDetails'),
);
const OtherPricingDetails = lazy(() =>
  import('../screens/Main/PackageDetails/OtherPricingDetails'),
);
const PackageLanding = lazy(() => import('../screens/Main/PackageLanding'));
const ResourseCategories = lazy(() => import('../screens/Main/ResourseCategories'));
const ResourceLanding = lazy(() => import('../screens/Main/ResourceLanding'));
const CreateNewResource = lazy(() => import('../screens/Main/CreateNewResource'));
const ResourceDetail = lazy(() => import('../screens/Main/ResourceDetail'));
const PricingStructure = lazy(() => import('../screens/Main/PricingStructure'));
const IntroSection = lazy(() => import('../screens/Main/IntroSection'));
const CampaignEmails = lazy(() => import('../screens/Main/CampaignEmails'));
const CreateEmailCampaign = lazy(() => import('../screens/Main/CreateEmailCampaign'));
const CampaignList = lazy(() => import('../screens/Main/CampaignList'));
const CampaignSection = lazy(() => import('../screens/Main/CampaignSection'));
const PromotionCategory = lazy(() => import('../screens/Main/PromotionCategory'));
const PromotionCategoryDetails = lazy(() =>
  import('../screens/Main/PromotionCategory/PromotionCategoryDetails'),
);
const PromotionCode = lazy(() => import('../screens/Main/PromotionCategory/PromotionCode'));
const ReferredBy = lazy(() => import('../screens/Main/ReferredBy/ReferredBy'));
const AddReferredBy = lazy(() => import('../screens/Main/AddReferredBy/AddReferredBy'));

const OTPCodes = lazy(() => import('../screens/Main/OTPCodes/OTPCodes'));
// rutvik practice
/* const CampaignView = lazy(() => import('../screens/Main/CampaignView'));
const CampaignSections = lazy(() => import('../screens/Main/CampaignSections'));
const CampaignEmailsss = lazy(() => import('../screens/Main/CampaignEmailsss')) */

// const Settings = lazy(() => import('../screens/Main/Settings'));
const key = process.env.REACT_APP_ENCRYPTION_KEY;
const userCookie = Cookies.get('user', { domain: rootSubdomain });
if (userCookie) {
  //const userData = JSON.parse(atob(userCookie));
  //const userData = crypto.createDecipher("aes-256-ctr",key).update(userCookie,"hex","utf-8");
  let bytes = CryptoJS.AES.decrypt(userCookie, key);
  const userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  console.log('hehe');
  console.log(userData);
  userType = (userData.userType && userData.userType.type) || null;
} else {
  if (
    window.location.href.indexOf('set-password') < 0 &&
    window.location.href.indexOf('forgot-password') < 0 &&
    window.location.href.indexOf('reset-password') < 0 &&
    window.location.href.indexOf('two-factor-authentication') < 0 &&
    window.location.href.indexOf('practice-account/create-account') < 0 &&
    window.location.href.indexOf('addusers') < 0 &&
    window.location.href.indexOf('create-account') < 0 &&
    window.location.href.indexOf('practice-account/pricing') < 0 &&
    window.location.href.indexOf('practice-account/payment') < 0 &&
    window.location.href.indexOf('sign-up/register') < 0 &&
    window.location.href.indexOf('sign-up/payment') < 0 &&
    window.location.href.indexOf('sign-up/pricing') < 0
  ) {
    // window.location = '/#/login';
    /*to pass no authorization urls*/
    let noAuthUrls = ['dialogstable']; // add urls here to access without auth
    let urlIndex = noAuthUrls.filter((responses) => window.location.href.indexOf(responses) > -1);
    /*to pass no authorization urls*/
    if (!urlIndex || !urlIndex.length) {
      window.location = '/#/login';
    } else {
      appNav = [
        {
          name: 'Dialogs Table',
          /* navImage: require('../assets/images/staff.png').default, */
          image: require('../assets/images/staff.png').default,
          navIcons: <TestIconActive />,
          path: 'dialogstable',
          element: null,
          navIconsAlt: <TestIcon />,
        },
      ];
    }
  }
}
//if (userType == '5eee274881372b2c2cb5de53' || userType == '5f0df2dd92a65472527476c7') {
if (userType == 'uberadmin') {
  appNav = [
    {
      name: 'Landing',
      path: '',
      element: <WaterMark />,
    },
    {
      name: 'Practices',
      image: require('../assets/images/dashboard.png').default,
      navIcons: <PracticeIcon />,
      path: 'practice-list-view',
      element: <PracticeListView />,
      navIconsAlt: <PracticeIconActive />,
      num: 1,
    },
    {
      name: 'NeuroScore Assessment',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <NeuroIcon />,
      path: 'neuro-score-detail',
      element: <NeuroScoreDetail />,
      navIconsAlt: <NeuroIconActive />,
      num: 1,
    },
    {
      name: 'Clinical Assessment',
      /* navImage: require('../assets/images/studios.jpg').default, */
      image: require('../assets/images/studios.jpg').default,
      navIcons: <ClinicalIcon />,
      path: 'clinical-assessments',
      element: <ClinicalAssessments />,
      navIconsAlt: <ClinicalIconActive />,
      num: 1,
    },

    {
      name: 'Onboarding Survey Detail',
      /* navImage: require('../assets/images/calendar.png').default, */
      image: require('../assets/images/calendar.png').default,
      navIcons: <SurveyIcon />,
      path: 'onboarding-survey-detail',
      element: <OnboardingSurveyDetail />,
      navIconsAlt: <SurveyIconActive />,
    },
    {
      name: 'Onboarding Survey',
      /* navImage: require('../assets/images/sales.png').default,  */
      image: require('../assets/images/sales.png').default,
      navIcons: <SurveyIcon />,
      path: 'on-boarding-survey',
      element: <OnBoardingServey />,
      navIconsAlt: <SurveyIconActive />,
      num: 1,
    },
    {
      name: 'Club Management',
      /* navImage: require('../assets/images/sales.png').default,  */
      image: require('../assets/images/sales.png').default,
      navIcons: <ClinicalIcon />,
      path: 'package',
      element: <PackageLanding />,
      navIconsAlt: <ClinicalIconActive />,
      num: 1,
    },
    {
      name: 'Create Neuro Questions',
      image: require('../assets/images/dashboard.png').default,
      navIcons: <TestIconActive />,
      path: 'create-neuro-questions',
      element: <CreateNeuroQuestions />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Add Practice Users',
      image: require('../assets/images/dashboard.png').default,
      navIcons: <TestIconActive />,
      path: 'add-practice-users/:practiceId',
      element: <AddPracticeUsers />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Add Practices',
      image: require('../assets/images/dashboard.png').default,
      navIcons: <TestIcon />,
      path: 'add-practices',
      element: <AddPractices />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Bulk Add Patients',
      /* navImage: require('../assets/images/appointments.png').default, */
      image: require('../assets/images/appointments.png').default,
      navIcons: <TestIconActive />,
      path: 'bulk-add-patients',
      element: <BulkAddPatients />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Practices',
      /* navImage: require('../assets/images/classes.png').default, */
      image: require('../assets/images/classes.png').default,
      navIcons: <TestIconActive />,
      path: 'practice-list-view',
      element: <PracticeListView />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Add Patients',
      /* navImage: require('../assets/images/clients.png').default, */
      image: require('../assets/images/clients.png').default,
      navIcons: <TestIconActive />,
      path: 'add-patients',
      element: <AddPatients />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Practice Detail',
      /* navImage: require('../assets/images/reports.png').default, */
      image: require('../assets/images/reports.png').default,
      navIcons: <TestIconActive />,
      path: 'practice-detail/:practiceId',
      element: <PracticeDetail />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'My Messages Practice',
      /* navImage: require('../assets/images/sales.png').default, */
      image: require('../assets/images/sales.png').default,
      navIcons: <TestIconActive />,
      path: 'my-messages-practice',
      element: <MyMessagesPractice />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'My Patients',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'my-patients',
      element: <MyPatients subscriptionPlan={subscriptionPlan} />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Program Detail',
      /* navImage: require('../assets/images/studios.jpg').default, */
      image: require('../assets/images/studios.jpg').default,
      navIcons: <TestIconActive />,
      path: 'program-detail/:clinicalAssessment/:programId',
      element: <ProgramDetail />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Admin/Doctor detail page',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'practice-detail-page/:practiceId',
      element: <PracticeDetailPage />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Create New Task',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'program-detail/create-new-task',
      element: <CreateNewTask />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Create New Course',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'program-detail/create-new-course',
      element: <CreateNewCourse />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'New Message',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'newmessage',
      element: <NewMessage />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Create New Question',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'create-newquestion/:clinicalAssessment',
      element: <CreateNewQuestion />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Create OnBoardingServey Question',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'create-onboarding-survey-question',
      element: <CreateOnBoardingSurveyQuestion />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Practice User Profile',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'practice-user-profile/:userId',
      element: <PracticeUserProfile />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Edit Patient',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'edit-patient/:userId',
      element: <EditPatient subscriptionPlan={subscriptionPlan} />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Clinical Assessment Detail',
      /* navImage: require('../assets/images/calendar.png').default, */
      image: require('../assets/images/calendar.png').default,
      navIcons: <TestIconActive />,
      path: 'clinical-assessment-detail/:clinicalName',
      element: <ClinicalAssessmentDetail />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Message',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'message/:receiverId',
      element: <Message />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Patient Pricing Detail',
      image: require('../assets/images/calendar.png').default,
      navIcons: <TestIconActive />,
      path: 'package-detail/:packageName',
      element: <PatientPricingDetails />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Other Pricing Detail',
      image: require('../assets/images/calendar.png').default,
      navIcons: <TestIconActive />,
      path: 'package-detail-other/:packagename',
      element: <OtherPricingDetails />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Practice Resources',
      /* navImage: require('../assets/images/sales.png').default,  */
      image: require('../assets/images/sales.png').default,
      navIcons: <ResourceWhite />,
      path: 'resources-categories',
      element: <ResourseCategories />,
      navIconsAlt: <ResourceOrange />,
      num: 1,
    },
    {
      name: 'Resources Landing',
      /* navImage: require('../assets/images/calendar.png').default, */
      image: require('../assets/images/calendar.png').default,
      navIcons: <TestIconActive />,
      path: 'resource-landing/:categoryId',
      element: <ResourceLanding />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Create New Resource',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'create-resource/:categoryId',
      element: <CreateNewResource />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Create Intro Section',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'create-intro-section',
      element: <IntroSection />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Campaign',
      /* navImage: require('../assets/images/studios.jpg').default, */
      image: require('../assets/images/studios.jpg').default,
      navIcons: <CampaignIcon />,
      path: 'campaign-list',
      element: <CampaignList />,
      navIconsAlt: <CampaignActiveIcon />,
      num: 1,
    },
    {
      name: 'Campaign Section',
      /* navImage: require('../assets/images/studios.jpg').default, */
      image: require('../assets/images/studios.jpg').default,
      navIcons: <TestIconActive />,
      path: 'campaign-section/:campaignId',
      element: <CampaignSection />,
      navIconsAlt: <TestIcon />,
    },

    {
      name: 'Campaign Email',
      /* navImage: require('../assets/images/studios.jpg').default, */
      image: require('../assets/images/studios.jpg').default,
      navIcons: <TestIconActive />,
      path: 'campaign-email-list/:campaignSectionId',
      element: <CampaignEmails />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Campaign Email Details',
      /* navImage: require('../assets/images/studios.jpg').default, */
      image: require('../assets/images/studios.jpg').default,
      navIcons: <TestIconActive />,
      path: 'campaign-email-detail/:id',
      element: <CreateEmailCampaign />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Campaign Email Details',
      /* navImage: require('../assets/images/studios.jpg').default, */
      image: require('../assets/images/studios.jpg').default,
      navIcons: <TestIconActive />,
      path: 'create-weekly-email',
      element: <CreateEmailCampaign />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Promotion ',
      // navImage: require('../assets/images/studios.jpg').default,
      image: require('../assets/images/studios.jpg').default,
      navIcons: <TestIconActive />,
      path: 'promo-type-list',
      element: <PromotionCategory />,
      navIconsAlt: <TestIcon />,
      num: 1,
    },
    {
      name: 'Referred By',
      // navImage: require('../assets/images/studios.jpg').default,
      image: require('../assets/images/studios.jpg').default,
      navIcons: <ReferredByIcon />,
      path: 'referred-by',
      element: <ReferredBy />,
      navIconsAlt: <ReferredByActive />,
      num: 1,
    },
    {
      name: 'OTP Codes',
      // navImage: require('../assets/images/studios.jpg').default,
      image: require('../assets/images/studios.jpg').default,
      path: 'otp-code',
      element: <OTPCodes />,
      num: 1,
    },
    {
      name: 'Add Referred By ',
      // navImage: require('../assets/images/studios.jpg').default,
      image: require('../assets/images/studios.jpg').default,
      navIcons: <TestIconActive />,
      path: 'add-referred-by',
      element: <AddReferredBy />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Promotion Details',
      // navImage: require('../assets/images/studios.jpg').default,
      image: require('../assets/images/studios.jpg').default,
      navIcons: <TestIconActive />,
      path: 'promo-category-detail/:id',
      element: <PromotionCategoryDetails />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Promotion Code Details',
      // navImage: require('../assets/images/studios.jpg').default,
      image: require('../assets/images/studios.jpg').default,
      navIcons: <TestIconActive />,
      path: 'promo-code-detail/:promoId',
      element: <PromotionCode />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Promotion Add Details',
      // navImage: require('../assets/images/studios.jpg').default,
      image: require('../assets/images/studios.jpg').default,
      navIcons: <TestIconActive />,
      path: 'add-promo-code',
      element: <PromotionCode />,
      navIconsAlt: <TestIcon />,
    },
  ];
} //else if (
//userType == '5eee274881372b2c2cb5de55' ||
//  userType == '5eee274881372b2c2cb5de56' ||
//  userType == '5f0dead0756a69de00c94e55' ||
//  userType == '5f0dead0756a69de00c94e57' ||
//  userType == '5f0deab8756a69de00c94e50' ||
//  userType == '5f0deab8756a69de00c94e52'
//) {
else if (userType == 'admin' || userType == 'doctor') {
  appNav = [
    {
      name: 'Dashboard',
      /* navImage: require('../assets/images/sales.png').default, */
      image: require('../assets/images/sales.png').default,
      navIcons: <SettingsIcon />,
      path: 'practice-dashboard',
      element: <PracticeDashboard />,
      navIconsAlt: <SettingsIconActive />,
      num: 1,
    },
    {
      name: 'Patients',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <PatientIcon />,
      path: 'my-patients',
      element: <MyPatients subscriptionPlan={subscriptionPlan} />,
      navIconsAlt: <PatientIconActive />,
      num: 1,
    },
    {
      name: 'Messages',
      /* navImage: require('../assets/images/sales.png').default,  */
      image: require('../assets/images/sales.png').default,
      navIcons: <MessagesIcon />,
      path: 'my-messages-practice',
      element: <MyMessagesPractice />,
      navIconsAlt: <MessagesIconActive />,
      num: 1,
    },
    {
      name: 'Practice Settings',
      /* navImage: require('../assets/images/sales.png').default, */
      image: require('../assets/images/sales.png').default,
      navIcons: <SettingsIcon />,
      path: 'practice-detail-page',
      element: <PracticeDetailPage />,
      navIconsAlt: <SettingsIconActive />,
      num: 1,
    },
    // {
    //   name: 'Practice Resources',
    //   /* navImage: require('../assets/images/sales.png').default, */
    //   image: require('../assets/images/sales.png').default,
    //   navIcons: <SurveyIcon />,
    //   path: 'resources',
    //   element: <AdminResources />,
    //   navIconsAlt: <SurveyIconActive />,
    //   num: 1,
    // },
    {
      name: 'Practice Resources',
      /* navImage: require('../assets/images/sales.png').default,  */
      image: require('../assets/images/sales.png').default,
      navIcons: <ResourceWhite />,
      path: 'resources-categories',
      element: <ResourseCategories />,
      navIconsAlt: <ResourceOrange />,
      num: 1,
    },
    {
      name: 'Referrals',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <ReferralIcon />,
      path: 'patient-referrals',
      element: <Referrals />,
      navIconsAlt: <ReferralActiveIcon />,
      num: 1,
    },
    {
      name: 'Add Practices',
      image: require('../assets/images/dashboard.png').default,
      navIcons: <TestIcon />,
      path: 'add-practices',
      element: <AddPractices />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Add Practice Users',
      image: require('../assets/images/dashboard.png').default,
      navIcons: <TestIconActive />,
      path: 'add-practice-users/:practiceId',
      element: <AddPracticeUsers />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Bulk Add Patients',
      /* navImage: require('../assets/images/appointments.png').default, */
      image: require('../assets/images/appointments.png').default,
      navIcons: <TestIconActive />,
      path: 'bulk-add-patients',
      element: <BulkAddPatients />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Clinical Assessment Detail',
      /* navImage: require('../assets/images/calendar.png').default, */
      image: require('../assets/images/calendar.png').default,
      navIcons: <TestIconActive />,
      path: 'clinical-assessment-detail/:clinicalName',
      element: <ClinicalAssessmentDetail />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Practices',
      /* navImage: require('../assets/images/classes.png').default, */
      image: require('../assets/images/classes.png').default,
      navIcons: <TestIconActive />,
      path: 'practice-list-view',
      element: <PracticeListView />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Add Patients',
      /* navImage: require('../assets/images/clients.png').default, */
      image: require('../assets/images/clients.png').default,
      navIcons: <TestIconActive />,
      path: 'add-patients',
      element: <AddPatients />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Practice Detail',
      /* navImage: require('../assets/images/reports.png').default, */
      image: require('../assets/images/reports.png').default,
      navIcons: <TestIconActive />,
      path: 'practice-detail/:practiceId',
      element: <PracticeDetail />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Clinical Assessments',
      /* navImage: require('../assets/images/studios.jpg').default, */
      image: require('../assets/images/studios.jpg').default,
      navIcons: <TestIconActive />,
      path: 'clinical-assessments',
      element: <ClinicalAssessments />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Program Detail',
      /* navImage: require('../assets/images/studios.jpg').default, */
      image: require('../assets/images/studios.jpg').default,
      navIcons: <TestIconActive />,
      path: 'program-detail/:clinicalAssessment/:programId',
      element: <ProgramDetail />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Admin/Doctor detail page',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'practice-detail-page/:practiceId',
      element: <PracticeDetailPage />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Create New Task',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'program-detail/create-new-task',
      element: <CreateNewTask />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Message',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'message/:receiverId',
      element: <Message />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'New Message',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'newmessage',
      element: <NewMessage />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Create New Question',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'create-newquestion/:clinicalAssessment/:practiceId',
      element: <CreateNewQuestion />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Practice User Profile',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'practice-user-profile/:userId',
      element: <PracticeUserProfile />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Edit Patient',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'edit-patient/:userId',
      element: <EditPatient subscriptionPlan={subscriptionPlan} />,
      navIconsAlt: <TestIcon />,
    },
    // {
    //   name: 'Practice Resources',
    //   /* navImage: require('../assets/images/sales.png').default,  */
    //   image: require('../assets/images/sales.png').default,
    //   navIcons: <SurveyIcon />,
    //   path: 'resources-categories',
    //   element: <ResourseCategories />,
    //   navIconsAlt: <SurveyIconActive />,
    //   num: 1,
    // },
    {
      name: 'Resources Landing',
      /* navImage: require('../assets/images/calendar.png').default, */
      image: require('../assets/images/calendar.png').default,
      navIcons: <TestIconActive />,
      path: 'resource-landing/:categoryId',
      element: <ResourceLanding />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Resources Detail',
      /* navImage: require('../assets/images/calendar.png').default, */
      image: require('../assets/images/calendar.png').default,
      navIcons: <TestIconActive />,
      path: 'resource-detail/:resourceId',
      element: <ResourceDetail />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Pricing',
      /* navImage: require('../assets/images/calendar.png').default, */
      image: require('../assets/images/calendar.png').default,
      // navIcons: <TestIconActive />,
      path: 'pricing-structure',
      element: <PricingStructure />,
      navIconsAlt: <TestIcon />,
    },
  ];
} else if (userType == '-1') {
  appNav = [
    {
      name: 'Add Practices',
      image: require('../assets/images/dashboard.png').default,
      navIcons: <TestIcon />,
      path: 'add-practices',
      element: <AddPractices />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Add Practice Users',
      image: require('../assets/images/dashboard.png').default,
      navIcons: <TestIconActive />,
      path: 'add-practice-users/:practiceId',
      element: <AddPracticeUsers />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Bulk Add Patients',
      /* navImage: require('../assets/images/appointments.png').default, */
      image: require('../assets/images/appointments.png').default,
      navIcons: <TestIconActive />,
      path: 'bulk-add-patients',
      element: <BulkAddPatients />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Clinical Assessment Detail',
      /* navImage: require('../assets/images/calendar.png').default, */
      image: require('../assets/images/calendar.png').default,
      navIcons: <TestIconActive />,
      path: 'clinical-assessment-detail/:clinicalName',
      element: <ClinicalAssessmentDetail />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Practices',
      /* navImage: require('../assets/images/classes.png').default, */
      image: require('../assets/images/classes.png').default,
      navIcons: <TestIconActive />,
      path: 'practice-list-view',
      element: <PracticeListView />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Add Patients',
      /* navImage: require('../assets/images/clients.png').default, */
      image: require('../assets/images/clients.png').default,
      navIcons: <TestIconActive />,
      path: 'add-patients',
      element: <AddPatients />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Practice Detail',
      /* navImage: require('../assets/images/reports.png').default, */
      image: require('../assets/images/reports.png').default,
      navIcons: <TestIconActive />,
      path: 'practice-detail/:practiceId',
      element: <PracticeDetail />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'My Messages Practice',
      /* navImage: require('../assets/images/sales.png').default, */
      image: require('../assets/images/sales.png').default,
      navIcons: <TestIconActive />,
      path: 'my-messages-practice',
      element: <MyMessagesPractice />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'My Patients',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'my-patients',
      element: <MyPatients subscriptionPlan={subscriptionPlan} />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Clinical Assessments',
      /* navImage: require('../assets/images/studios.jpg').default, */
      image: require('../assets/images/studios.jpg').default,
      navIcons: <TestIconActive />,
      path: 'clinical-assessments',
      element: <ClinicalAssessments />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Program Detail',
      /* navImage: require('../assets/images/studios.jpg').default, */
      image: require('../assets/images/studios.jpg').default,
      navIcons: <TestIconActive />,
      path: 'program-detail/:clinicalAssessment/:programId',
      element: <ProgramDetail />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Admin/Doctor detail page',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'practice-detail-page/:practiceId',
      element: <PracticeDetailPage />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Create New Task',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'program-detail/create-new-task',
      element: <CreateNewTask />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Message',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'message/:receiverId',
      element: <Message />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'New Message',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'newmessage',
      element: <NewMessage />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Create New Question',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'create-newquestion/:clinicalAssessment/:practiceId',
      element: <CreateNewQuestion />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Practice User Profile',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'practice-user-profile/:userId',
      element: <PracticeUserProfile />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Edit Patient',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'edit-patient/:userId',
      element: <EditPatient subscriptionPlan={subscriptionPlan} />,
      navIconsAlt: <TestIcon />,
    },
    {
      name: 'Dialogs Table',
      /* navImage: require('../assets/images/staff.png').default, */
      image: require('../assets/images/staff.png').default,
      navIcons: <TestIconActive />,
      path: 'dialogstable',
      element: <DialogsTable />,
      navIconsAlt: <TestIcon />,
    },
    // {
    //   name: 'Studios',
    //   /* navImage: require('../assets/images/studios.jpg').default, */
    //   image: require('../assets/images/studios.jpg').default,
    //   navIcons: <TestIconActive />,
    //   path: 'studios',
    //   element: <Studios />,
    // },
    // {
    //   name: 'Settings',
    //   /* navImage: require('../assets/images/classes-settings.jpg').default, */
    //   image: require('../assets/images/classes-settings.jpg').default,
    //   navIcons: <TestIconActive />,
    //   path: 'settings',
    //   element: (
    //     <TimeZoneProvider>
    //       <StateProvider>
    //         <CompanyProvider>
    //           <TaxDetailsProvider>
    //             <ClassCategoriesProvider>
    //               <RevenueCategoriesProvider>
    //                 <CancelPolicyProvider>
    //                   <Settings />
    //                 </CancelPolicyProvider>
    //               </RevenueCategoriesProvider>
    //             </ClassCategoriesProvider>
    //           </TaxDetailsProvider>
    //         </CompanyProvider>
    //       </StateProvider>
    //     </TimeZoneProvider>
    //   ),
    //   children: settingsNav,
    //   listItemStyle: {
    //     borderTop: '1px #4860CF solid',
    //   },
    // },
  ];
}

export default appNav;
