import React from 'react';
import { Modal } from '@material-ui/core';
import useStyles from './Loader.styles';

const Loader = () => {
  const classes = useStyles();
  return (
    <Modal open BackdropProps={{ style: { backgroundColor: 'rgba(255,255,255,0.8)' } }}>
      <div className={classes.loaderouter}>
        <img src={require('../../assets/loader/loader.gif').default} alt="Loader" />
        <span>Loading...</span>
      </div>
    </Modal>
  );
};

export default Loader;
